
import { mapGetters } from 'vuex'
import { UiImage, UiLink } from '~/components/atoms'

export default {
  components: {
    UiImage,
    UiLink,
  },
  props: {
    logo: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: '8',
    },
    center: Boolean,
    withoutLink: Boolean,
  },
  computed: {
    ...mapGetters('context', ['lang', 'language', 'isGlobalMarketplace']),
    label(): string {
      return this.$store.getters['context/label'] || ''
    },
    alt(): string {
      return 'Recharge Logo'
    },
  },
}
